<template>
  <div id="app">
    <header>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <div class="collapse navbar-collapse justify-content-center">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link class="nav-link" to="/" active-class="active-link">
                  Home
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/login"
                  active-class="active-link"
                >
                  Login
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/register"
                  active-class="active-link"
                >
                  Register
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/blog"
                  active-class="active-link"
                >
                  Blog
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <main>
      <router-view></router-view>
    </main>

    <footer class="text-center py-3">
      <p>© 2024 My Health App. All Rights Reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  color: #333;
}

header {
  margin-bottom: 20px;
  padding-top: 20px; /* Adds space above the navbar */
}

main {
  min-height: 60vh;
  padding: 20px;
}

footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  color: #6c757d;
}

.navbar-nav .nav-link {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.navbar-nav .nav-link:hover {
  color: #007bff;
}

.navbar-nav .active-link {
  font-weight: bold;
  color: #007bff;
  text-decoration: underline;
}

.navbar-nav {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.nav-item {
  margin-right: 20px;
}

.navbar {
  background-color: transparent;
}

.navbar-nav .nav-link {
  padding: 10px 15px;
}
</style>
