<template>
  <div class="admin-dashboard">
    <h2>Admin Dashboard</h2>
    <p>Welcome to the Admin Dashboard.</p>
    <h3>User List</h3>
    <table class="user-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.email">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.role }}</td>
          <td>
            <button @click="editUser(user)" class="btn btn-secondary">
              Edit
            </button>
            <button @click="deleteUser(user.email)" class="btn btn-danger">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [],
    };
  },
  created() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      const storedUsers = JSON.parse(localStorage.getItem("users")) || [];
      this.users = storedUsers;
    },
    editUser(user) {
      alert(`Edit functionality for ${user.name} is not implemented yet.`);
    },
    deleteUser(email) {
      if (confirm("Are you sure you want to delete this user?")) {
        let users = JSON.parse(localStorage.getItem("users")) || [];
        users = users.filter((user) => user.email !== email);
        localStorage.setItem("users", JSON.stringify(users));
        this.loadUsers(); // Refresh the user list
      }
    },
  },
};
</script>

<style scoped>
.admin-dashboard {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.user-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.btn {
  margin: 5px;
}
</style>
