<template>
  <div class="rating-container">
    <h3>Rate This Service</h3>
    <div class="stars">
      <span
        v-for="star in 5"
        :key="star"
        :class="['star', { filled: star <= userRating }]"
        @click="setRating(star)"
      >
        ★
      </span>
    </div>
    <p v-if="averageRating !== null">
      Average Rating: {{ averageRating.toFixed(1) }} / 5
    </p>
    <button @click="submitRating" class="btn btn-primary">Submit Rating</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userRating: 0,
      averageRating: null,
      totalRatings: [],
    };
  },
  mounted() {
    // Load existing ratings from localStorage
    const storedRatings = JSON.parse(localStorage.getItem("ratings")) || [];
    this.totalRatings = storedRatings;
    this.calculateAverageRating();
  },
  methods: {
    setRating(star) {
      this.userRating = star;
    },
    submitRating() {
      if (this.userRating > 0) {
        // Add the new rating to the array
        this.totalRatings.push(this.userRating);
        // Store the updated ratings in localStorage
        localStorage.setItem("ratings", JSON.stringify(this.totalRatings));
        // Calculate the new average rating
        this.calculateAverageRating();
        alert("Thank you for your rating!");
      } else {
        alert("Please select a rating.");
      }
    },
    calculateAverageRating() {
      if (this.totalRatings.length > 0) {
        const sum = this.totalRatings.reduce((a, b) => a + b, 0);
        this.averageRating = sum / this.totalRatings.length;
      } else {
        this.averageRating = null;
      }
    },
  },
};
</script>

<style scoped>
.rating-container {
  text-align: center;
  margin: 20px 0;
}

.stars {
  display: inline-block;
}

.star {
  font-size: 30px;
  cursor: pointer;
  color: #ddd;
}

.star.filled {
  color: #f39c12;
}

.btn {
  margin-top: 10px;
}
</style>
