import { createRouter, createWebHistory } from "vue-router";
import Home from "@/components/Home.vue";
import UserDashboard from "@/components/UserDashboard.vue";
import AdminDashboard from "@/components/AdminDashboard.vue";
import Login from "@/components/UserLogin.vue";
import Register from "@/components/UserRegister.vue";
import Blog from "@/components/Blog.vue"; // Import the Blog component

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/user-dashboard",
    name: "UserDashboard",
    component: UserDashboard,
    meta: { requiresAuth: true, roles: ["User"] },
  },
  {
    path: "/admin-dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: { requiresAuth: true, roles: ["Admin"] },
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
