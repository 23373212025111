<template>
  <div class="home">
    <div class="jumbotron text-center">
      <h1 class="display-4">Welcome to My Health App</h1>
      <img
        src="@/assets/healthcare.jpg"
        alt="Healthcare"
        class="img-fluid mx-auto d-block"
      />
    </div>

    <!-- Add DataTable below the image -->
    <div class="table-section">
      <h2>Health Data Overview</h2>
      <DataTable />
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable.vue";

export default {
  name: "Home",
  components: {
    DataTable,
  },
};
</script>

<style scoped>
.jumbotron {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 0.5rem;
}

.display-4 {
  font-weight: 700;
}

.img-fluid {
  max-width: 50%; /* Adjust this value to make the image larger or smaller */
  height: auto;
  border-radius: 0.5rem;
}

.home {
  text-align: center;
}

.table-section {
  margin-top: 2rem;
}

h2 {
  margin-bottom: 1rem;
}
</style>
